import * as React from 'react';
import { auth } from '../config/firebase';
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

function Footer() {

  const currentyear = new Date().getFullYear();
const logout = async () => {
    await signOut(auth);
}
  return (

<footer className="w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">
  {/* Grid */}
  <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6 mb-10">
    <div className="col-span-full hidden lg:col-span-1 lg:block">
      <a className="flex-none text-xl font-semibold dark:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="/" aria-label="Brand">printGEN</a>
      <p className="mt-3 text-xs sm:text-sm text-gray-600 dark:text-gray-400"> {currentyear}</p>
    </div>
    {/* End Col */}

    <div>
      <h4 className="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">Info</h4>

      <div className="mt-3 grid space-y-3 text-sm">
      <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="/">Home</a></p>
        <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="/pricing">Pricing</a></p>
        <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="/pricing" >FAQ</a></p>
      </div>
    </div>
    {/* End Col */}

    <div>
      <h4 className="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">Company</h4>

      <div className="mt-3 grid space-y-3 text-sm">
        <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">Blog</a> <span className="inline text-blue-600 dark:text-blue-500">— Coming Soon</span></p>
        <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="/contact">Contact</a></p>
      </div>
    </div>
    {/* End Col */}

    <div>
      <h4 className="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">Resources</h4>

      <div className="mt-3 grid space-y-3 text-sm">
        <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="/profile">Profile</a></p>
        <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="/profile">Subscription Settings</a></p>
        <p><button onClick={logout} className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">Log Out</button></p>

      </div>
    </div>
    {/* End Col */}

    <div>
      <h4 className="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">Developers</h4>

      <div className="mt-3 grid space-y-3 text-sm">
      <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">Api</a> <span className="inline text-blue-600 dark:text-blue-500">— Coming Soon</span></p>
      </div>

    </div>
    {/* End Col */}
  </div>
  {/* End Grid */}

  <div className="pt-5 mt-5 border-t border-gray-200 dark:border-gray-700">
    <div className="sm:flex sm:justify-between sm:items-center">
      <div className="flex items-center gap-x-3">
        {/* Language Dropdown */}

        {/* End Language Dropdown */}

        <div className="space-x-4 text-sm ms-4">
          <a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">Terms</a>
          <a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">Privacy</a>
          <a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">Status</a>
        </div>
      </div>

      <div className="justify-right items-right sm:flex sm:justify-between sm:items-center">
        <div className="mt-3  text-right sm:text-left">
          <a className="flex-none  text-xl font-semibold dark:text-white" href="/" aria-label="Brand">printGEN</a>
          <p className="mt-1 text-xs sm:text-sm text-gray-600 dark:text-gray-400"> {currentyear}</p>
        </div>

        {/* Social Brands */}
        <div className="space-x-4">

        </div>
        {/* End Social Brands */}
      </div>
      {/* End Col */}
    </div>
  </div>
</footer>

  );
}
export default Footer;