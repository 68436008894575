import { onAuthStateChanged } from "firebase/auth";
import { db } from "../config/firebase";
import { auth } from "../config/firebase";
import { useState, useContext } from "react";
import { AppContext } from "../App";
import { addDoc, onSnapshot } from "firebase/firestore";
import { collection } from "firebase/firestore";
import { set } from "firebase/database";




function Checkout() {
    const {user} = useContext(AppContext);
    const prices = {};
    const [isDisabled, setDisabled] = useState(false);
    // Checkout handler
    async function subscribe(event) {
        setDisabled(true);
      event.preventDefault();
        console.log(user.uid);
    
    
      const selectedPrice = {
        price: 'price_1OPzYnAezPjn9LGJ7OzpRH3w',
        quantity: 1,
      };
      // For prices with metered billing we need to omit the quantity parameter.
      // For all other prices we set quantity to 1.
    
      const checkoutSession = {
        automatic_tax: true,
        tax_id_collection: true,
        collect_shipping_address: true,
        allow_promotion_codes: true,
        line_items: [selectedPrice],
        success_url: window.location.origin,
        cancel_url: window.location.origin,
        metadata: {
          key: 'value',
        },
      };
    
    
    
      const docRef = await addDoc(collection(db, 'customers', user.uid, 'checkout_sessions'), checkoutSession);

      // Wait for the CheckoutSession to get attached by the extension
      const unsubscribe = onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          // Show an error to your customer and then inspect your function logs.
          alert(`An error occurred: ${error.message}`);
          document.querySelectorAll('button').forEach((b) => (b.disabled = false));
        }
        if (url) {
          window.location.assign(url);
      
          // Unsubscribe when the redirection happens
          unsubscribe();
        }
      });
    }
    //end subscribe

    return (

          <button onClick={subscribe} disabled={isDisabled} className="animate-button py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" type="submit">Subscribe                
            {isDisabled ? (<span className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full ml-2" role="status" aria-label="loading">
            
        </span>) : null}</button>


    );
  }
  export default Checkout;