import * as React from 'react';
import { AppContext } from "../App";

function HeaderBar() {
  const {user} = React.useContext(AppContext);
  const[userDisplayName, setUserDisplayName] = React.useState("Login");
  

  const CheckDisplayName = () => {

    if (user !== null ){
      setUserDisplayName("👋 Welcome, " + user.email + "!")
    } else {
      setUserDisplayName("Login")
    }
  }
  
  React.useEffect(() => {
    CheckDisplayName();
  },[user])

  return (

    <header className="bg-gray-200 relative z-100 flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full  text-sm py-4 dark:bg-slate-900">
  <nav className=" max-w-[85rem] w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between" aria-label="Global">
    <a className="flex items-center text-xl font-semibold  dark:text-white" href="/">printGEN <img className='inline h-6 ml-2' src='/logo.png'></img> </a>
    <div className="flex flex-row items-center gap-5 mt-5 sm:justify-end sm:mt-0 sm:ps-5">

      <a className="font-medium text-gray-600 hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="/library">Library</a>
      <a className="font-medium text-gray-600 hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="/pricing">Pricing <span className='text-xs'>(its free!)</span></a>
      <a className="font-medium text-gray-600 hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="/contact">Contact</a>
      <a className="font-medium text-gray-600 hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-500 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="/profile">{userDisplayName}</a>
    </div>
  </nav>
</header>

  );
}
export default HeaderBar;