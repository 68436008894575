import { AppContext } from '../App';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Checkout from './checkout';
import FAQ from './faq';

import { db, storage } from '../config/firebase';
import { doc, getDoc } from 'firebase/firestore';

function Pricing() {
  const {user} = React.useContext(AppContext);
  const navigate = useNavigate();
  const [statsDoc, setStatsDoc] = useState({});
  useEffect(() => {
    const getStats = async () => {
      const docRef = doc(db, "public", "stats");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setStatsDoc(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    };

    getStats();

  }, []);

  return (
    <div>
      {/* Features */}
      <div className="overflow-hidden">
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          {/* Title */}
          <div className="mx-auto max-w-2xl mb-8 lg:mb-14 text-center">
            <h2 className="text-3xl lg:text-4xl text-gray-800 font-bold dark:text-gray-200">
              Like what you see?
            </h2>
            <p className='dark:text-gray-200 py-3'>Help support a very small Melbourne based business and be involved in shaping the future of the platform!</p>
          </div>
          {/* End Title */}
            {/* Features */}
            <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
              {/* Grid */}
              <div class="grid gap-6 grid-cols-2 sm:gap-12 lg:grid-cols-3 lg:gap-8">
                {/* Stats */}
                <div>
                  <h4 class="text-lg sm:text-xl font-semibold text-gray-800 dark:text-neutral-200">Members</h4>
                  <p class="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-blue-600">{statsDoc.totalUsers}</p>
                  <p class="mt-1 text-gray-500 dark:text-neutral-500">free members</p>
                </div>
                {/* End Stats */}

                {/* Stats */}
                <div>
                  <h4 class="text-lg sm:text-xl font-semibold text-gray-800 dark:text-neutral-200">Supporters</h4>
                  <p class="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-blue-600">{statsDoc.stripeRoleUsers}</p>
                  <p class="mt-1 text-gray-500 dark:text-neutral-500">paid supporters</p>
                </div>
                {/* End Stats */}

                {/* Stats */}
                <div>
                  <h4 class="text-lg sm:text-xl font-semibold text-gray-800 dark:text-neutral-200">Server Costs Covered</h4>
                  <p class="mt-2 sm:mt-3 text-4xl sm:text-6xl font-bold text-red-500">${-143.52+statsDoc.stripeRoleUsers*4}</p>
                  <p class="mt-1 text-gray-500 dark:text-neutral-500">this month</p>
                </div>
                {/* End Stats */}
              </div>
              {/* End Grid */}
            </div>
            {/* End Features */}
          <div className="relative  mx-auto">
            {/* Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6 lg:gap-8">

              {/* Card */}
              <div className="p-4 relative z-10 bg-white border rounded-xl md:p-10 dark:bg-slate-900 dark:border-gray-700">
              {user?.stripeRole === "free" && <span className="absolute  top-5 right-5 py-1 px-2 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                  <svg className="flex-shrink-0 w-3 h-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" /><path d="m9 12 2 2 4-4" /></svg>
                  Your Current Plan
                </span>}
                <h3 className="text-xl font-bold text-gray-800 dark:text-gray-200">Free</h3>
                <div className="text-sm text-gray-500">Generate any models for free!</div>

                <div className="mt-5">
                  <span className="text-6xl font-bold text-gray-800 dark:text-gray-200">$0</span>
                  <span className="ms-3 text-gray-500">USD / monthly</span>
                </div>

                <div className="mt-5 grid sm:grid-cols-1 gap-y-2 py-4 first:pt-0 last:pb-0 sm:gap-x-6 sm:gap-y-0">

                  {/* List */}
                  <ul className="space-y-2 text-sm sm:text-base">
                    <li className="flex space-x-3">
                      <span className="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                        <svg className="flex-shrink-0 h-3.5 w-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12" /></svg>
                      </span>
                      <span className="text-gray-800 dark:text-gray-200">
                        5000 model generations a day
                      </span>
                    </li>
                    <li className="flex space-x-3">
                      <span className="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                        <svg className="flex-shrink-0 h-3.5 w-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12" /></svg>
                      </span>
                      <span className="text-gray-800 dark:text-gray-200">
                        No Ads!
                      </span>
                    </li>
                    <li className="flex space-x-3">
                      <span className="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                        <svg className="flex-shrink-0 h-3.5 w-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12" /></svg>
                      </span>
                      <span className="text-gray-800 dark:text-gray-200">
                        Download support-free models ready to slice
                      </span>
                    </li>
                    <li className="flex space-x-3">
                      <span className="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-gray-50 text-gray-500 dark:bg-gray-800">
                        <svg className="flex-shrink-0 h-3.5 w-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                      </span>
                      <span className="text-gray-800 dark:text-gray-200">
                        Request and vote for new generators
                      </span>
                    </li>
                    <li className="flex space-x-3">
                      <span className="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-gray-50 text-gray-500 dark:bg-gray-800">
                        <svg className="flex-shrink-0 h-3.5 w-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
                      </span>
                      <span className="text-gray-800 dark:text-gray-200">
                        Commercial use not allowed
                      </span>
                    </li>


                  </ul>
                  {/* End List */}


                </div>

                <div className="mt-5 grid grid-cols-2 gap-x-4 py-4 first:pt-0 last:pb-0">
                  <div>
                    <p className="text-sm text-gray-500">No credit card required.</p>
                  </div>

                  <a href='./profile' className="flex justify-end">
                    <button type="button" className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">Sign Up</button>
                  </a>
                </div>
              </div>
              {/* End Card */}

              {/* Card */}
              <div className="p-4 relative z-10 bg-white border rounded-xl md:p-10 dark:bg-slate-900 dark:border-gray-700">
              {user?.stripeRole === "premium" && <span className="absolute  top-5 right-5 py-1 px-2 inline-flex items-center gap-x-1 text-xs font-medium bg-teal-100 text-teal-800 rounded-full dark:bg-teal-500/10 dark:text-teal-500">
                  <svg className="flex-shrink-0 w-3 h-3" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" /><path d="m9 12 2 2 4-4" /></svg>
                  Your Current Plan
                </span>}
                <h3 className="text-xl font-bold text-gray-800 dark:text-gray-200">Support Us</h3>
                <div className="text-sm text-gray-500">Help feed our server costs and us</div>

                <div className="mt-5">
                  <span className="text-6xl font-bold text-gray-800 dark:text-gray-200">$4</span>
                  <span className="ms-3 text-gray-500">USD / monthly</span>
                </div>

                <div className="mt-5 grid sm:grid-cols-1 gap-y-2 py-4 first:pt-0 last:pb-0 sm:gap-x-6 sm:gap-y-0">

                  {/* List */}
                  <ul className="space-y-2 text-sm sm:text-base">
                    <li className="flex space-x-3">
                      <span className="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                        <svg className="flex-shrink-0 h-3.5 w-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12" /></svg>
                      </span>
                      <span className="text-gray-800 dark:text-gray-200">
                      STEP file access
                      </span>
                    </li>

                    <li className="flex space-x-3">
                      <span className="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                        <svg className="flex-shrink-0 h-3.5 w-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12" /></svg>
                      </span>
                      <span className="text-gray-800 dark:text-gray-200">
                      Request and vote for new generators
                      </span>
                    </li>
                    <li className="flex space-x-3">
                      <span className="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                        <svg className="flex-shrink-0 h-3.5 w-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12" /></svg>
                      </span>
                      <span className="text-gray-800 dark:text-gray-200">
                        Discord Access
                      </span>
                    </li>
                    <li className="flex space-x-3">
                      <span className="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                        <svg className="flex-shrink-0 h-3.5 w-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12" /></svg>
                      </span>
                      <span className="text-gray-800 dark:text-gray-200">
                        Early access to beta generators
                      </span>
                    </li>
                    <li className="flex space-x-3">
                      <span className="mt-0.5 h-5 w-5 flex justify-center items-center rounded-full bg-blue-50 text-blue-600 dark:bg-blue-800/30 dark:text-blue-500">
                        <svg className="flex-shrink-0 h-3.5 w-3.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12" /></svg>
                      </span>
                      <span className="text-gray-800 dark:text-gray-200">
                        Commercial use allowed
                      </span>
                    </li>
                  </ul>
                  {/* End List */}


                </div>

                <div className="mt-5 grid grid-cols-2 gap-x-4 py-4 first:pt-0 last:pb-0">
                  <div>
                    <p className="text-sm text-gray-500">Cancel anytime.</p>
                    <p className="text-sm text-gray-500">*In accordance with fair use policy</p>
                  </div>
                  {user?.stripeRole === "premium" &&
                    <div className="flex justify-end">
                      <button onClick={() => navigate('/profile')} type="button" className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">Manage</button>
                    </div>
                  }{user?.stripeRole != "premium" &&
                  <div className="flex justify-end">
                    <Checkout></Checkout>
                  </div>
                  }
                </div>
              </div>
              {/* End Card */}



            </div>
            {/* End Grid */}

            {/* SVG Element */}
            <div className="hidden md:block absolute top-0 end-0 translate-y-16 translate-x-16">
              <svg className="w-16 h-auto text-orange-500" width="121" height="135" viewBox="0 0 121 135" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164" stroke="currentColor" strokeWidth="10" strokeLinecap="round" />
                <path d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5" stroke="currentColor" strokeWidth="10" strokeLinecap="round" />
                <path d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874" stroke="currentColor" strokeWidth="10" strokeLinecap="round" />
              </svg>
            </div>
            {/* End SVG Element */}

            {/* SVG Element */}
            <div className="hidden md:block absolute bottom-0 start-0 translate-y-16 -translate-x-16">
              <svg className="w-56 h-auto text-cyan-500" width="347" height="188" viewBox="0 0 347 188" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 82.4591C54.7956 92.8751 30.9771 162.782 68.2065 181.385C112.642 203.59 127.943 78.57 122.161 25.5053C120.504 2.2376 93.4028 -8.11128 89.7468 25.5053C85.8633 61.2125 130.186 199.678 180.982 146.248L214.898 107.02C224.322 95.4118 242.9 79.2851 258.6 107.02C274.299 134.754 299.315 125.589 309.861 117.539L343 93.4426" stroke="currentColor" strokeWidth="7" strokeLinecap="round" />
              </svg>
            </div>
            {/* End SVG Element */}
          </div>

          <div className="mt-7 text-center">
            <p className="text-xs text-gray-400">
              Prices in USD. Taxes may apply.
            </p>
          </div>
        </div>
      </div>
      {/* End Features */}

      <FAQ></FAQ>
    </div>
  );
}
export default Pricing;