// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { GoogleAuthProvider, getAuth, signInWithRedirect } from "firebase/auth";
import { getFirestore} from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from 'firebase/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAPgHyVzLxsJRFdsL8vJ1XLUkld9RWFRxU",
  authDomain: "printgen.app",
  projectId: "fir-cad-gen",
  storageBucket: "fir-cad-gen.appspot.com",
  messagingSenderId: "358979267505",
  appId: "1:358979267505:web:6d3a05225522b49bf4ab90",
  measurementId: "G-XDB3YR1EYD"
};

const provider = new GoogleAuthProvider();

const signInWithGoogle = () => {
  signInWithRedirect(auth, provider).then((result) => {
  
  }).catch((error) => {
    console.log(error);
  }); 
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
export default signInWithGoogle;

