import * as React from 'react';





function FAQ() {

  return (
    <div id='faq'>
      {/* FAQ */}
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          {/* Title */}
          <div className="max-w-2xl mx-auto mb-10 lg:mb-14">
            <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">You might be wondering...</h2>
          </div>
          {/* End Title */}

          <div className="max-w-2xl mx-auto divide-y divide-gray-200 dark:divide-neutral-700">
            <div className="py-8 first:pt-0 last:pb-0">
              <div className="flex gap-x-5">
                <svg className="flex-shrink-0 mt-1 size-6 text-gray-500 dark:text-neutral-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>

                <div>
                  <h3 className="md:text-lg font-semibold text-gray-800 dark:text-neutral-200">
                    Can I cancel at anytime?
                  </h3>
                  <p className="mt-1 text-gray-500 dark:text-neutral-300">
                    Yes, you can cancel anytime you will keep access to the platform until the end of your billing cycle.
                  </p>
                </div>
              </div>
            </div>

            <div className="py-8 first:pt-0 last:pb-0">
              <div className="flex gap-x-5">
                <svg className="flex-shrink-0 mt-1 size-6 text-gray-500 dark:text-neutral-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>

                <div>
                  <h3 className="md:text-lg font-semibold text-gray-800 dark:text-neutral-200">
                    Can I use the model for commercial use?
                  </h3>
                  <p className="mt-1 text-gray-500 dark:text-neutral-300">
                    Yes you can print the models for commercial use with the paid tier, and can sell products with digital models in them.
                  </p>
                </div>
              </div>
            </div>
            
            <div className="py-8 first:pt-0 last:pb-0">
              <div className="flex gap-x-5">
                <svg className="flex-shrink-0 mt-1 size-6 text-gray-500 dark:text-neutral-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>

                <div>
                  <h3 className="md:text-lg font-semibold text-gray-800 dark:text-neutral-200">
                    Do you use AI to generate the models?
                  </h3>
                  <p className="mt-1 text-gray-500 dark:text-neutral-300">
                    We do NOT use AI to generate models. AI is not used at any stage of the pipeline.
                  </p>
                </div>
              </div>
            </div>
            <div className="py-8 first:pt-0 last:pb-0">
              <div className="flex gap-x-5">
                <svg className="flex-shrink-0 mt-1 size-6 text-gray-500 dark:text-neutral-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>

                <div>
                  <h3 className="md:text-lg font-semibold text-gray-800 dark:text-neutral-200">
                    When is API access available?
                  </h3>
                  <p className="mt-1 text-gray-500 dark:text-neutral-300">
                    We intend to release API access for the platform in the future. Possibly even home assistant integration.
                  </p>
                </div>
              </div>
            </div>
            <div className="py-8 first:pt-0 last:pb-0">
              <div className="flex gap-x-5">
                <svg className="flex-shrink-0 mt-1 size-6 text-gray-500 dark:text-neutral-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>

                <div>
                  <h3 className="md:text-lg font-semibold text-gray-800 dark:text-neutral-200">
                    Can the platform slice the models for me?
                  </h3>
                  <p className="mt-1 text-gray-500 dark:text-neutral-300">
                    We are working on adding slicing functionality to the platform. This will be available in the future but likly only for Bambu or Prusa printers, or printers running Mainsail/Fluidd.
                  </p>
                </div>
              </div>
            </div>
            <div className="py-8 first:pt-0 last:pb-0">
              <div className="flex gap-x-5">
                <svg className="flex-shrink-0 mt-1 size-6 text-gray-500 dark:text-neutral-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>

                <div>
                  <h3 className="md:text-lg font-semibold text-gray-800 dark:text-neutral-200">
                    Can I share my account details?
                  </h3>
                  <p className="mt-1 text-gray-500 dark:text-neutral-300">
                    You can share your account details with your team, but you are responsible for the security of your account.
                  </p>
                </div>
              </div>
            </div>

            <div className="py-8 first:pt-0 last:pb-0">
              <div className="flex gap-x-5">
                <svg className="flex-shrink-0 mt-1 size-6 text-gray-500 dark:text-neutral-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>

                <div>
                  <h3 className="md:text-lg font-semibold text-gray-800 dark:text-neutral-200">
                    How can I delete my account?
                  </h3>
                  <p className="mt-1 text-gray-500 dark:text-neutral-300">
                    Contact us to delete your account. Once your account is deleted, you will lose access to the platform and all your data will be deleted.
                  </p>
                </div>
              </div>
            </div>

            <div className="py-8 first:pt-0 last:pb-0">
              <div className="flex gap-x-5">
                <svg className="flex-shrink-0 mt-1 size-6 text-gray-500 dark:text-neutral-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>

                <div>
                  <h3 className="md:text-lg font-semibold text-gray-800 dark:text-neutral-200">
                    I have an idea for a generator.
                  </h3>
                  <p className="mt-1 text-gray-500 dark:text-neutral-300">
                     We would love to hear it! You can vote for existing ideas or submit a new one with a premium account.
                  </p>
                </div>
              </div>
            </div>

            <div className="py-8 first:pt-0 last:pb-0">
              <div className="flex gap-x-5">
                <svg className="flex-shrink-0 mt-1 size-6 text-gray-500 dark:text-neutral-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>

                <div>
                  <h3 className="md:text-lg font-semibold text-gray-800 dark:text-neutral-200">
                    I have an idea for a generator and why won't you make it!
                  </h3>
                  <p className="mt-1 text-gray-500 dark:text-neutral-300">
                    Keep in mind creating the generator is a complex process and we need ideas that are have a wide appeal. A bad concept would be a generator that only a few people would use.
                  </p>
                </div>
              </div>
            </div>
            
            <div className="py-8 first:pt-0 last:pb-0">
              <div className="flex gap-x-5">
                <svg className="flex-shrink-0 mt-1 size-6 text-gray-500 dark:text-neutral-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg>

                <div>
                  <h3 className="md:text-lg font-semibold text-gray-800 dark:text-neutral-200">
                    I have an idea for a generator and I will hand you a blank cheque to make it!
                  </h3>
                  <p className="mt-1 text-gray-500 dark:text-neutral-300">
                    Get in touch 😀
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End FAQ */}
      
    </div>
  );
}
export default FAQ;