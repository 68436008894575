import React, { useState, useContext, useEffect, useRef } from 'react';
import { AppContext } from "../App";
import { storage } from "../config/firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { db, auth } from '../config/firebase';
import { getFirestore, collection, query, where, getDocs, addDoc, doc, onSnapshot } from 'firebase/firestore';
import { set } from 'firebase/database';



const ObjectMover = () => {

  const [formStructure, setFormStructure] = useState({});
  const [errors, setErrors] = useState({});
  const {user} = React.useContext(AppContext);

  const [timeDifference, setTimeDifference] = useState({ hours: 0, minutes: 0 });
  const [generatedURL, setGeneratedURL] = useState(null);
  const [status, setStatus] = useState(''); // New state for status
  const [isDisabled, setDisabled] = useState(false);
  const [isInvalid, setIsInvalid] = useState("");
  const [STEPurl, setSTEPurl] = useState('');
  const [userformData, setUserFormData] = useState({ "slug": useParams().id, inputs: [] });

  const [localSTLurl, setLocalSTLurl] = useState('');
  const formData = useRef([]);
  const slug = useParams();
  const navigate = useNavigate();
  const { setSTLurl, isMetric, setIsMetric } = useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      const dataCollection = collection(db, 'objects');
      const slugQuery = query(dataCollection, where('slug', '==', slug.id));
      const storageRef = ref(storage, `demo-meshes/${slug.id}.stl`);
      try {
        const stlUrl = await getDownloadURL(storageRef);
        setSTLurl(stlUrl);
      } catch (error) {
        //do nothing as the file does not exist 
      };

      try {
        const querySnapshot = await getDocs(slugQuery);

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            setFormStructure(data);

            formData.current = data.inputs.map((input) => {


              return { value: input.valuefromuser, nicename: input.nicename, validstring: input.validstring, slug: input.slug };

            });

            console.log(formData);
          });
        } else {
          console.log('Document not found!');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };

    fetchData();

  }, []); //this should only run once on page load hence the brackets here

  //metric to imperial conversion switch watchdog
  const handleSelectChange = (event) => {
    const value = event.target.value;
    setIsMetric(value === 'mm');
  };



  function Update(event, formData, errors, setErrors, nicename) {
    const updatedMap = formData.current.map(item => {
      var defaultvalue = 0;
      if (isMetric) {
        defaultvalue = parseFloat(event.target.value);
      } else {
        defaultvalue = parseFloat(event.target.value) * 25.4;
      }
      console.log(defaultvalue);
      if (item.slug === event.target.id) {
        return { value: defaultvalue, nicename: nicename, validstring: event.target.getAttribute('validstring'), slug: event.target.id };
      }
      return item;
    });
    formData.current = updatedMap;
    console.log(updatedMap);
    setErrors({});
    // now I am going to test the map to make sure it is valid
    let validmessage = "All values are valid";
    for (const item of updatedMap) {
      const value = parseFloat(item.value);
      const validstring = item.validstring;

      if (validstring) {
        // string exists continuing
        const valids = validstring.split(',').map((valid) => {
          const regExp = /[a-zA-Z]/;
          if (regExp.test(valid)) {
            var compareValue = valid.replace('>', '');
            compareValue = compareValue.replace('<', '');
            // now it should just be the variable name
            updatedMap.forEach((innerItem) => {
              if (innerItem.slug === compareValue) {
                if (valid.includes('>')) {
                  var otherValue = innerItem.value;
                  const otherName = innerItem.nicename;
                  if (value < otherValue) {
                    otherValue = isMetric ? otherValue : otherValue / 25.4;
                    validmessage = `This input should be greater than ${otherName} which is ${otherValue.toFixed(3)}`;
                    setErrors({
                      ...errors,
                      [item.slug]: validmessage
                    });
                  }
                } else if (valid.includes('<')) {
                  var otherValue = innerItem.value;
                  const otherName = innerItem.nicename;
                  if (value > otherValue) {
                    otherValue = isMetric ? otherValue : otherValue / 25.4;
                    validmessage = `This input should be less than ${otherName} which is ${otherValue.toFixed(3)}`;
                    setErrors({
                      ...errors,
                      [item.slug]: validmessage
                    });
                  }
                }
              }
            });

          } else { // doesn't contain letters so we can check its number state instead
            if (valid.includes('>')) {
              var min = parseFloat(valid.replace('>', ''));
              if (value <= min) {
                min = isMetric ? min : min / 25.4;
                validmessage = `The value of ${item.slug} is not valid, it should be greater than ${min.toFixed(3)}`;
                setErrors({
                  ...errors,
                  [item.slug]: validmessage
                });
              }
            } else if (valid.includes('<')) {
              var max = parseFloat(valid.replace('<', ''));
              if (value >= max) {
                max = isMetric ? max : max / 25.4;
                validmessage = `The value of ${item.slug} is not valid, it should be less than ${max.toFixed(3)}`;
                setErrors({
                  ...errors,
                  [item.slug]: validmessage
                });
                console.log(errors);
              }
            }
          }

        });
      }

    }

  }




  const handleGenerateURL = async () => {
    setDisabled(true);
    var userId = "";
    if (auth.currentUser === null) {
      userId = "public";
    } else {
      const user = auth.currentUser;
      userId = user.uid;
    }
    //if they left a feild empty make it 0
    formData.current.forEach((item) => {
      if (item.value === undefined) {
        item.value = 0;
      }
    });

    const generationsRef = collection(db, `users/${userId}/generations`);
    let premium = "";
    if (user) {
      if(user.stripeRole === "premium"){
        premium = "premium";
      }
    }

    const newGenerationRef = await addDoc(generationsRef, {
      slug: slug.id,
      inputs: formData.current,
      timestamp: new Date(),
      meshURL: "",
      stepURL: "",
      userPremium: premium,
      status: "requested"
    });

    const generationDocRef = doc(db, `users/${userId}/generations/${newGenerationRef.id}`);

    const unsubscribe = onSnapshot(generationDocRef, (doc) => {
      const data = doc.data();
      setStatus(data.status); // Update status state
      if (data.meshURL) {
        getDownloadURL(ref(storage, data.meshURL))
          .then((url) => {
            setSTLurl(url);
            setLocalSTLurl(url);
            setDisabled(false);
          });
      }
      if (data.stepURL) {
        getDownloadURL(ref(storage, data.stepURL))
          .then((url) => {
            setSTEPurl(url);
          });
      }
      if (data.status === "completed") {
        setDisabled(false);
      }
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  };

  if (!formStructure) {
    return <div>Loading...</div>;
  }

  return (
    <div component="form" className="m-2 border rounded-xl shadow-sm p-6 dark:bg-slate-800 dark:border-gray-700">
      <h1 className='text-4xl dark:text-white inline-block'>{formStructure.nicename}</h1>
      <select className="float-right  inline mt-1  py-2 px-2 pe-9 inline-block  border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
        value={isMetric ? "mm" : "inch"} // Set select value based on isMetric state
        onChange={handleSelectChange} // Add onChange event handler
      >
        <option value="mm">mm</option>
        <option value="inch">inch</option>

      </select>
      {formStructure.labelwarn && (
        <div className="bg-yellow-50 border border-yellow-200 text-sm text-yellow-800 rounded-lg p-4 my-5 dark:bg-yellow-800/10 dark:border-yellow-900 dark:text-yellow-500" role="alert">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="flex-shrink-0 size-4 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z"></path>
                <path d="M12 9v4"></path>
                <path d="M12 17h.01"></path>
              </svg>
            </div>
            <div className="ms-4">
              <h3 className="text-sm font-semibold">
                Warning
              </h3>
              <div className="mt-1 text-sm text-yellow-700">
                {formStructure.labelwarn}
              </div>
            </div>
          </div>
        </div>
      )}
      {formStructure.labelinfo && (
        <div className="bg-blue-100 border border-blue-200 text-gray-800 rounded-lg p-4 mt-4 dark:bg-blue-800/10 dark:border-blue-900 dark:text-white" role="alert">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="flex-shrink-0 size-4 mt-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M12 16v-4"></path>
                <path d="M12 8h.01"></path>
              </svg>
            </div>
            <div className="ms-3">
              <h3 className="font-semibold">
                Info
              </h3>
              <div className="mt-2 text-sm text-gray-600 dark:text-neutral-400">
                {formStructure.labelinfo}
              </div>

            </div>
          </div>
        </div>
      )}
      <form>
        {formStructure.inputs?.map((fielddata, index) => (
          <span id={fielddata.id} key={fielddata.id} style={{ Border: 'solid' + 'red' }}>
            <label htmlFor="input-label" className="block text-sm font-medium  pt-5 dark:text-white">{fielddata.nicename}</label>
            <p className='text-sm mb-2 text-slate-400'>{fielddata.desc}</p>
            <input onChange={(e) => Update(e, formData, errors, setErrors, fielddata.nicename)} defaultValue={isMetric ? fielddata.valuefromuser : (fielddata.valuefromuser / 25.4).toFixed(2)} id={fielddata.slug} validstring={fielddata.validstring} className="inline-block peer py-3 px-4 block w-4/5 border-gray-200 rounded-lg text-base focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" />
            <p className='inline-block  text-white ml-2'>{isMetric ? "mm" : "inch"}</p>
            <p className="error"></p>
            {errors[fielddata.slug] && <div className="mt-2 bg-red-100 border border-red-200 text-sm text-red-800 rounded-lg p-4 dark:bg-red-800/10 dark:border-red-900 dark:text-red-500" role="alert">
              <span className="font-bold">Error</span> {errors[fielddata.slug]}
            </div>}
          </span>
        ))}
        {generatedURL && (
          <div>
            <p>Generated URL:</p>
            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
              {generatedURL}
            </div>
          </div>
        )}
        {!isInvalid && (
          <button
            id="generateSubmitButton"
            variant="contained"
            disabled={isDisabled}
            onClick={handleGenerateURL}
            className="py-3 mt-10 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-800 text-gray-800 hover:border-gray-500 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:border-white dark:text-white dark:hover:text-gray-300 dark:hover:border-gray-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          >
            {isDisabled ? (
              <>
                {status}
                <span className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full ml-2" role="status" aria-label="loading">
                  <span className="sr-only">Loading...</span>
                </span>
              </>
            ) : (
              'Generate Model'
            )}
          </button>
        )}
        {isDisabled || !localSTLurl ? null : <a

          className="py-3 mt-10 ml-2 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-800 text-gray-800 hover:border-gray-500 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:border-white dark:text-white dark:hover:text-gray-300 dark:hover:border-gray-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          href={localSTLurl} download={formStructure.slug}
        >
          Download STL
        </a>
        }
        {user?.stripeRole === "premium" ? (
          isDisabled || !STEPurl ? null : <a

            className="py-3 mt-10 ml-2 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-800 text-gray-800 hover:border-gray-500 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:border-white dark:text-white dark:hover:text-gray-300 dark:hover:border-gray-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            href={STEPurl} download={formStructure.slug}
            >
            Download STEP
          </a>
          
        ): (
          isDisabled || !localSTLurl ? null : <a

          className="py-3 mt-10 ml-2 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-800 text-gray-800 hover:border-gray-500 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:border-white dark:text-white dark:hover:text-gray-300 dark:hover:border-gray-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
          href="/pricing" 
        >
          Unlock STEP
        </a>
        )}
        {isInvalid && (
          <button type="button" className="py-3 mt-10 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" disabled>
            {isInvalid}
          </button>
        )}
      </form>
      {timeDifference.hours !== 0 || timeDifference.minutes !== 0 && (
        <p className="mt-2 text-sm text-red-500">You are out of tokens. You must wait at least {timeDifference.hours} hours and {timeDifference.minutes} minutes. Or <a className="text-blue-600 decoration-2 hover:underline font-medium dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href='../pricing'>subscribe now</a></p>
      )}

    </div>
  );
};

export default ObjectMover;
