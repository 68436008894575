

// HelloWorld.js
import React, { useState, useEffect } from 'react';
import { db } from '../config/firebase';
import { collection, getDocs } from 'firebase/firestore';

import ObjectMover from "./MeshRequester.js"
import ThreeDviewer from './ThreeDViewer';


const Single = () => {


  return (
    <div  className="md:flex items-center " >
        <div className='h-[50rem]	sm:h-screen md:w-2/3' >
            <ThreeDviewer/>
        </div>
        <div className='absolute top-32 right-8 animate-bounce sm:hidden'>
          <p className='inline-block text-gray-400'>Scroll Bar</p>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="gray" className="size-6 inline-block">
              <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
            </svg>
        </div>


        <div className='absolute sm:hidden w-8 backdrop-blur-sm  bg-gradient-to-b from-transparent via-gray-700/50 to-transparent border-solid h-[55rem] top-0 right-0 z-0'></div>
        <div className='h-fit md:w-1/3' >
            < ObjectMover/>
        </div>
    </div>
  );
}

export default Single;
