// HelloWorld.js
import React, { useState, useEffect } from 'react';
import { db, storage } from '../config/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';

const Library = () => {
  const [objects, setObjects] = useState([]);
  const [imageUrls, setImageUrls] = useState({});
  const objectCollectionRef = collection(db, "objects");

  useEffect(() => {
    const getObjects = async () => {
      const data = await getDocs(objectCollectionRef);
      const objectsArray = data.docs.map((doc) => ({...doc.data(), id: doc.id}));
      setObjects(objectsArray);

      // Fetch image URLs from Firebase Storage
      const urls = await Promise.all(objectsArray.map(async (object) => {
        const imageRef = ref(storage, object.thumbnail);
        const url = await getDownloadURL(imageRef);
        return { [object.id]: url };
      }));

      // Combine all URLs into a single object
      const urlsObject = urls.reduce((acc, curr) => ({ ...acc, ...curr }), {});
      setImageUrls(urlsObject);
    };

    getObjects();
  }, []);

  return (
    <div className='min-h-[80vh]'>
      <h1 className='text-4xl dark:text-white text-center my-10'>Library</h1>
      <div className="text-center">
        {objects.map((object) => {
          return (
            <a href={`single/${object.slug}`} key={object.slug} className=" text-left inline-block w-80 m-4 bg-white border shadow-sm rounded-xl dark:bg-slate-900 dark:border-gray-700 dark:shadow-slate-700/[.7]">
              <div className="w-full h-56 rounded-t-xl relative">
                  {imageUrls[object.id] && (
                    <img 
                      className="w-full relative h-auto z-10 rounded-t-xl" 
                      src={imageUrls[object.id]} 
                      alt="Image of the part" 
                    />
                  )}
                <div className="animate-spin absolute top-[47%] left-[47%] z-0 size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500" role="status" aria-label="loading">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
              
              <div className="p-4 md:p-5">
                <h3 className="text-lg font-bold text-gray-800 dark:text-white my-2">
                  {object.nicename}
                </h3>
                <p className="mt-1 text-gray-500 dark:text-gray-400">
                  {object.desc}
                </p>
                <p className="mt-5 text-xs text-gray-500 dark:text-gray-500">
                  {object.slug}
                </p>
              </div>
            </a>
          );
        })}
      </div>
      <a href='/pricing'><h1 className='text-4xl dark:text-white text-center self-center mt-24'>More coming soon!</h1>
      <p className='text-center mt-2 dark:text-gray-400 mb-24 sm:mb-2'>New generators coming too slow? Want to pitch your own?<span className='text-blue-600'> Help support the platform!</span></p></a>
    </div>
  );
}

export default Library;
