import React, { useState } from 'react';
import { db, auth } from '../config/firebase';
import { doc, setDoc } from 'firebase/firestore';

function AdminSettings() {
  const [csvArray, setCsvArray] = useState([]);
  const [slug, setSlug] = useState('');
  const [labelInfo, setLabelInfo] = useState('');
  const [labelError, setLabelError] = useState('');
  const [nicename, setNicename] = useState('');
  const [thumbnail, setThumbnail] = useState('');

  const csvToArray = (csv) => {
    const rows = csv.trim().split('\n');
    const headers = rows[0].split('\t').map(header => header.trim().replace(/'/g, ''));
    const result = [];

    for (let i = 1; i < rows.length; i++) {
      const values = rows[i].split('\t').map(value => value.trim().replace(/'/g, ''));
      const obj = {};
      headers.forEach((header, index) => {
        obj[header] = values[index];
      });
      result.push(obj);
    }

    return result;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function(e) {
        const contents = e.target.result;
        const array = csvToArray(contents);
        setCsvArray(array);
        console.log('CSV Array:', array);
      };
      reader.readAsText(file);
    }
  };

  const handleProcessClick = async () => {
    const combinedObject = {
      slug,
      labelInfo,
      labelError,
      nicename,
      thumbnail,
      inputs: csvArray,
    };

    console.log('Combined Object:', combinedObject);

    try {
      const docRef = doc(db, 'objects', slug);
      await setDoc(docRef, combinedObject);
      console.log('Document successfully written!');
    } catch (error) {
      console.error('Error writing document: ', error);
    }
  };

  return (
    <div className='container mx-auto bg-slate-50'>
      <h1>Admin Settings</h1>
      <input type="file" id="csvFileInput" accept=".csv" onChange={handleFileChange} /><br />

      <label htmlFor="slugInput">Slug:</label>
      <input type="text" id="slugInput" value={slug} onChange={(e) => setSlug(e.target.value)} /><br />

      <label htmlFor="labelinfoInput">Label Info:</label>
      <input type="text" id="labelinfoInput" value={labelInfo} onChange={(e) => setLabelInfo(e.target.value)} /><br />

      <label htmlFor="labelerrorInput">Label Error:</label>
      <input type="text" id="labelerrorInput" value={labelError} onChange={(e) => setLabelError(e.target.value)} /><br />

      <label htmlFor="nicenameInput">Nicename:</label>
      <input type="text" id="nicenameInput" value={nicename} onChange={(e) => setNicename(e.target.value)} /><br />

      <label htmlFor="thumbnailInput">Thumbnail:</label>
      <input type="text" id="thumbnailInput" value={thumbnail} onChange={(e) => setThumbnail(e.target.value)} /><br />

      <button id="processButton" onClick={handleProcessClick}>Process</button>
    </div>
  );
}

export default AdminSettings;
