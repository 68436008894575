import { Canvas } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { Environment, OrbitControls, CameraControls, DefaultLoadingManager, useProgress, Html } from "@react-three/drei";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DDSLoader } from "three-stdlib";
import { Suspense, useState, useContext, useEffect, useRef } from "react";
import { Box3, Vector3, Mesh, MeshPhongMaterial } from 'three';
import { AppContext } from "../App";

import bedMeshPrusamk4 from "../models/mk4_bed.stl";
import bedMeshx1 from "../models/bbl-3dp-X1.stl";
import bedMesha1m from "../models/bbl-3dp-A1M.stl";

const Scene = (props) => {
  console.log("About to show the url the loader is going to run");
  console.log(props.urlSource);
  
  const stlmesh = useLoader(STLLoader, props.urlSource);

  // Create a mesh to calculate the bounding box
  const mesh = new Mesh(stlmesh, new MeshPhongMaterial({ color: "#4d80f0" }));
  const box = new Box3().setFromObject(mesh);
  const center = new Vector3();
  console.log(center);
  box.getCenter(center);
  //mesh.position.sub(center);  // Center the mesh
   // Rotate the mesh 90 degrees around the X-axis
   mesh.rotateX(-Math.PI/2); // Rotate 90 degrees (PI/2 radians)
  // Log the bounding box
  console.log('Bounding Box:', {
    min: box.min,
    max: box.max,
    center: center,
  });
  return (
    <primitive object={mesh} scale={0.001} />
  );
};

const BuildPlate = (props) => {
  let bedMesh;
  if (props.prefBedMesh === "Bambu Lab X1 Build Plate") {
    bedMesh = bedMeshx1;
  } else if (props.prefBedMesh === "Prusa MK4 Build Plate") {
    bedMesh = bedMeshPrusamk4;
  } else if (props.prefBedMesh === "Bambu Lab A1M Build Plate") {
    bedMesh = bedMesha1m;
  } else {
    bedMesh = bedMeshx1;
  }

  const obj = useLoader(STLLoader, bedMesh);

  return (
    <mesh receiveShadow geometry={obj}>
      <meshPhongMaterial color="grey" />
    </mesh>
  );
};

const ThreeDviewer = () => {
  const { stlUrl, prefBedMesh } = useContext(AppContext);
  const genMeshRef = useRef();
  const cameraControlsRef = useRef();

  function Loader() {
    const { active, progress, loaded } = useProgress();

    return <Html center>{progress} % loaded</Html>;
  }

  return (
    <div style={{ height: "100%" }}>
      <Canvas style={{ height: "100%" }} camera={{ position: [0.5, 0.5, 0.5], fov: 30 }}>
        <Suspense fallback={<Loader />}>
          <BuildPlate castShadow receiveShadow prefBedMesh={prefBedMesh} />
          <Scene castShadow receiveShadow urlSource={stlUrl} meshRefence={genMeshRef} />

          <ambientLight intensity={0.5} />
          <directionalLight castShadow position={[2.5, 8, 5]} />
          <CameraControls ref={cameraControlsRef} draggingSmoothTime={0.1} panSpeed={0.5} rotateSpeed={0.4} />
        </Suspense>
      </Canvas>
    </div>
  );
};

export default ThreeDviewer;
