import "./index.css";
import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { DDSLoader } from "three-stdlib";
import { Suspense, useState, createContext, useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';

import HeaderBar from "./components/header.js"
import Footer from "./components/footer.js"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Library from './components/library.js';
import Single from "./components/single.js";
import Pricing from "./components/pricing.js";
import Home from "./components/home.js";
import SignIn from "./components/sign-in.js";
import SignUp from "./components/sign-up.js";
import Profile from "./components/profile.js";
import Checkout from "./components/checkout.js";
import { auth } from './config/firebase';
import usePersistentState from './usePersistentState';
import Contact from "./components/contact.js";
import useAuth from './components/useAuth';

THREE.DefaultLoadingManager.addHandler(/\.dds$/i, new DDSLoader());

export const AppContext = createContext();
export const useUser = () => useContext(AppContext);

export default function App() {
  const [stlUrl, setSTLurl] = useState("https://firebasestorage.googleapis.com/v0/b/fir-cad-gen.appspot.com/o/funnel-example.stl?alt=media&token=9df85f97-6a48-4715-8784-e6853a929600");
  const user = useAuth();
  const [isMetric, setIsMetric] = usePersistentState('isMetric', true);
  const [prefBedMesh, setPrefBedMesh] = usePersistentState('prefBedMesh', "Bambu Lab X1 Build Plate");



  return (
    
    <AppContext.Provider value={{ stlUrl, setSTLurl, user, isMetric, setIsMetric, prefBedMesh, setPrefBedMesh }}>
      <script src="./node_modules/preline/dist/preline.js"></script>
      <HeaderBar />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/library" element={<Library />} />
          <Route path="/single/:id" element={<Single />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
      <Footer />
    </AppContext.Provider>

  );
};