import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../config/firebase';

const useAuth = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchTokenAndSetUser = async (currentUser) => {
      if (currentUser) {
        try {
          await currentUser.getIdToken(true); // Force refresh token
          const idTokenResult = await currentUser.getIdTokenResult();
          const { stripeRole } = idTokenResult.claims;
          setUser({ ...currentUser, stripeRole: stripeRole || 'free' });
        } catch (error) {
          console.error('Error fetching token:', error);
          setUser(null);
        }
      } else {
        setUser(null);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, fetchTokenAndSetUser);

    return () => unsubscribe();
  }, []);

  return user;
};

export default useAuth;
