import { onAuthStateChanged, signOut, sendPasswordResetEmail } from 'firebase/auth';
import * as React from 'react';
import { auth } from '../config/firebase';
import { useState, useContext } from 'react';
import SignIn from './sign-in';
import { AppContext } from "../App";
import { functions } from '../config/firebase';
import { httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';
import AdminSettings from './adminsettings';
import { set } from 'firebase/database';

function Profile() {
    const [passwordResetSent, setPasswordResetSent] = useState(false);
    const { user, prefBedMesh, setPrefBedMesh } = useContext(AppContext);
    const navigate = useNavigate();
    const [stripeloading, setstripeLoading] = useState(false);

    const stripePortalButton = async () => {
        setstripeLoading(true);
        try {
            const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
            const { data } = await functionRef({
                returnUrl: window.location.origin,
                locale: 'auto',
            });
            window.location.assign(data.url);
        } catch (error) {
            console.error('Error calling function:', error);
        }
    };

    const logout = async (event) => {
        event.preventDefault();
        await signOut(auth);
        window.location.assign('/');
    };

    const sendPasswordReset = async () => {
        try {
            await sendPasswordResetEmail(auth, user.email);
            console.log('Password reset email sent!');
            setPasswordResetSent(true);
        } catch (error) {
            console.error('Error sending password reset email:', error);
        }
    };

    const handleBedMeshChange = (event) => {
        setPrefBedMesh(event.target.value);
    };

    return (
        <div>
            {user ? (
                <div>
                    <main className="w-full max-w-md mx-auto p-6">
                        <div className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
                            <div className="p-4 sm:p-7">
                                <div className="text-center">
                                    <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">Already signed in</h1>
                                    <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                                        Your email is {user?.email}
                                    </p>
                                    <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                                        Your current subscription is: 
                                    </p>
                                    {user.stripeRole === "premium" && <p className='animate-character'> {user.stripeRole}</p>}
                                    {user.stripeRole === "free" && <h1 className="block text-2xl font-bold text-gray-800 uppercase dark:text-white"> {user.stripeRole}</h1>}
                                </div>
                                <div className="mt-5">
                                    <div className="grid gap-y-4">
                                        {/* Form Group */}
                                        <div>
                                            <p className="hidden text-xs text-red-600 mt-2" id="email-error">Please include a valid email address so we can get back to you</p>
                                        </div>
                                        {/* End Form Group */}
                                        <button onClick={sendPasswordReset} className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">Send Password Reset Email</button>
                                        {passwordResetSent && <p className="text-green-500">Password reset email sent! Please check your email.</p>}
                                        <button onClick={logout} className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">Sign Out</button>
                                        {user.stripeRole === "premium" && <button onClick={stripePortalButton} disabled={stripeloading} className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">Manage Subscription</button>}
                                        {user.stripeRole === "free" && <button onClick={() => navigate('/pricing')} className="animate-character outline-blue-500 outline-1 outline w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">Go Premium</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-7 bg-white border p-6 border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
                            <h1 className="block text-center pb-6 text-2xl font-bold text-gray-800 dark:text-white">Preferences</h1>
                            <label for="hs-select-label" className="block text-sm font-medium mb-2 dark:text-white">Visualise Buildplate</label>
                           <select
                                id="hs-select-label"
                                className="py-3 px-4 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
                                value={prefBedMesh}
                                onChange={handleBedMeshChange}
                            >
                                <option value="Bambu Lab X1 Build Plate">Bambu Lab X1</option>
                                <option value="Bambu Lab A1M Build Plate">Bambu Lab A1 Mini / Prusa Mini</option>
                                <option value="Prusa MK4 Build Plate">Prusa MK4 / MK3</option>
                            </select>
                        </div>
                    </main>
                    {user.uid == "1NIFxFSD1EYTrrchXyRWk8lclv53" ? <AdminSettings /> : null}
                </div>
                
            ) : (
                <SignIn />
            )}
            
        </div>
    );
}

export default Profile;
